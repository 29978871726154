import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Layout, Main, Stack } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const styles = {
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  },
  text: {
    fontWeight: `body`,
    color: `omegaDark`
  }
}

export default props => {
  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
          />
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Text variant='h3' sx={styles.text}>
                My name is Ioannis and I am a Software Engineer. I am from Cyprus and I currently
                live in London. I am working for Ocado Technology, building Ocado Smart Platform, a world-class
                suite of solutions for operating online grocery businesses. I have a degree in Electrical and
                Computer Engineering from the National Technical University of Athens and a master's degree
                in Software Engineering from Imperial College London.
              </Text>
              <Text variant='h3' sx={styles.text}>
                I am experienced in both back and front-end development, including Java, Scala and Node.js for back-end,
                and Javascript and React for front-end. I am also experienced in both SQL and NoSQL databases,
                including PostgreSQL and MongoDB, and other technologies such as AWS, GitLab CI and microservices.
              </Text>
              <Text variant='h3' sx={styles.text}>
                In this blog, I will share my thoughts about technologies that I am interested in and experiences gained
                through my work and personal projects. I hope you find my posts useful and interesting! If you want to keep in touch
                you can reach me through the contact form <a href="/contact/">here</a> or my social media listed below.
              </Text>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}
